.dash-navbar-top {
  margin-bottom: 0;
  min-height: 61px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
  line-height: 1;
}

.dash-navbar-top .nb-btn-toggle {
  position: absolute;
  left: 16px;
  top: 13.5px;
  font-size: 21px;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  color: #d3d1dd;
  outline: 0;
}

.dash-navbar-top .navbar-toggle {
  border-width: 0;
  font-size: 24px;
  color: #d3d1dd;
  padding: 0 10px;
  margin-top: 14px;
  margin-right: 15px;
}
.dash-navbar-top .navbar-toggle:hover,
.dash-navbar-top .navbar-toggle:focus {
  background-color: transpardnt;
}

.dash-navbar-left {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #2a2d35;
  width: 250px;
  height: 100vh;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999;
  padding-top: 5px;
  -webkit-transition: left 300ms;
  -o-transition: left 300ms;
  transition: left 300ms;
}

.dash-navbar-left .nb-nav-title {
  text-transform: uppercase;
  color: #7f799c;
  border-bottom: 1px solid rgba(255, 152, 0, 0.1);
  padding: 10px 25px;
  font-size: 11px;
  letter-spacing: 1px;
}
