.pricing-container {
  display: flex;
  flex-direction: row;
  min-height: 75vh;
}

.price-row {
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1rem;
  width: 100vw;
}

.intake {
  height: 40rem;
  background: radial-gradient(rgb(211, 211, 211), rgb(255, 255, 255));
  margin: 0.25rem;
  border-radius: 10%;
  padding: 2rem;
}

.intake p {
  text-align: justify;
}

.intake ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
}

.breakthrough {
  height: 45rem;
  background: radial-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
  align-items: center;
  justify-content: center;
  margin: 1rem;
  color: white;
  border-radius: 10%;
  padding: 2rem;
}

.breakthrough ul {
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
  font-size: 1.25rem;
}

.breakthrough p {
  text-align: justify;
}

.ongoing {
  height: 40rem;
  background: radial-gradient(rgb(0, 0, 0), rgb(41, 40, 40));
  color: white;
  margin: 1rem;
  border-radius: 10%;
}

.ongoing ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
}
