.homepage-container {
  min-height: 80vh;
}

.homepage-head {
  display: flex;
  flex-direction: row;
  min-height: 20rem;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;
}

.homepage-head h2 {
  font-family: "DDN";
  color: white;
  font-weight: bold;
  font-size: 4rem;
}

.homepage-head img {
  height: 20rem;
}

.homepage-head-mobile {
  display: flex;
  flex-direction: row;
  min-height: 20rem;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
  font-family: "DDN";
  color: white;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
}

.homepage-head-mobile img {
  height: 8rem;
}

.homepage-head-mobile h2 {
  font-size: 2.5rem;
}

.homepage-about {
  display: flex;
  flex-direction: row;
  min-height: 20rem;
  align-items: center;
}

.homepage-about img {
  height: 30rem;
}

.homepage-about h4 {
  padding: 3rem;
}

.homepage-venn {
  display: flex;
  flex-direction: row;
  min-height: 40rem;
  align-items: center;
  padding: 3rem 5rem 3rem 5rem;
  color: white;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
}

.homepage-venn-mobile {
  color: white;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
}

.homepage-testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30rem;
}

.homepage-testimonials-mobile {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  justify-content: center;
}

.carousel-text {
  font-size: 1rem;
}

.homepage-contact {
  display: flex;
  flex-direction: row;
  min-height: 20rem;
  align-items: center;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
  color: white;
}

.homepage-contact-mobile {
  min-height: 20rem;
  background: linear-gradient(rgb(37, 79, 124), rgb(25, 55, 87));
  color: white;
}
